// @ts-check
import Section5050 from '@/components/_sections/Section5050'
import SectionImageText from '@/components/_sections/SectionImageText'
import SectionJournalText from '@/components/_sections/SectionJournalText'
import SectionMarqueeBanner from '@/components/_sections/SectionMarqueeBanner'
import SectionMarqueeText from '@/components/_sections/SectionMarqueeText'
import SectionNewsletterForm from '@/components/_sections/SectionNewsletterForm'
import SectionPortraitImages from '@/components/_sections/SectionPortraitImages'
import SectionSvgBanner from '@/components/_sections/SectionSvgBanner'
import SectionText from '@/components/_sections/SectionText'
import Splits59ProductCard from '@/components/ProductCard'
import Splits59Button from '@button'
import { ShopstoryProvider as Provider } from '@shopstory/core/react'
import Link from 'next/link'
import React, { forwardRef } from 'react'
import { AnalyticsListItemProvider } from '@/hooks/Analytics'

const ProductCard = ({ product, featuredImage, featuredVideo, disableQuickBuy, disableLabel }) => {
  const adjustedProduct = featuredImage ? { ...product, primaryImage: featuredImage } : product

  return (
    <AnalyticsListItemProvider list="shopstory" position={-1}>
      <Splits59ProductCard product={adjustedProduct} featuredVideo={featuredVideo} disableQuickBuy={disableQuickBuy} disableLabel={disableLabel} PriceChange={true} />
    </AnalyticsListItemProvider>
  )
}

/**
 * @typedef ButtonProps
 * @type {object}
 * @property {string} label
 * @property {string} kind
 * @property {string} [href]
 * @property {string} color
 * @property {boolean} isWhite
 */

/**
 * @type {import('react').ForwardRefRenderFunction<HTMLButtonElement, ButtonProps>}
 */
const ButtonRender = ({ label, kind, href, ...restProps }, ref) => {
  if (kind === 'color') {
    return (
      <Splits59Button kind={kind} color={restProps.color} href={href} ref={ref}>
        {label}
      </Splits59Button>
    )
  }

  if (kind === 'secondary') {
    return (
      <Splits59Button kind={kind} isWhite={restProps.isWhite} href={href} ref={ref}>
        {label}
      </Splits59Button>
    )
  }

  return (
    <Splits59Button kind={kind} href={href} ref={ref}>
      {label}
    </Splits59Button>
  )
}

const Button = forwardRef(ButtonRender)

const sectionNameToSectionComponent = {
  Section5050,
  SectionImageText,
  SectionJournalText,
  SectionMarqueeBanner,
  SectionMarqueeText,
  SectionNewsletterForm,
  SectionPortraitImages,
  SectionSvgBanner,
  SectionText
}

const Section = ({ data }) => {
  const { __sectionComponentName, ...sectionComponentProps } = data

  const SectionComponent = sectionNameToSectionComponent[__sectionComponentName]

  if (!SectionComponent) {
    throw new Error('Unsupported section type')
  }

  return <SectionComponent {...sectionComponentProps} />
}

function NextLinkProvider({ Component, componentProps, values }) {
  return (
    <Link href={values.pagePath} passHref>
      <Component {...componentProps} href={values.pagePath} />
    </Link>
  )
}

export const ShopstoryProvider = ({ children }) => {
  return (
    <Provider
      components={{
        ProductCard,
        Section
      }}
      buttons={{
        Button: Button
      }}
      links={{
        PageLink: NextLinkProvider
      }}
    >
      {children}
    </Provider>
  )
}
