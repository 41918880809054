import { Suspense, useEffect, useMemo, useState, useCallback, useRef } from 'react'
import dynamic from 'next/dynamic'

import { Shopstory } from '@shopstory/core/react'
import { ShopstoryClient } from '@shopstory/core/client'

import fetchGlobalSettings from '../data/contentful/fetchGlobalSettings'
import { shopstoryConfig } from '../shopstory/config'
import { ShopstoryProvider } from '../shopstory/ShopstoryProvider'

import fetchHome from '@/data/contentful/fetchHome'

const Section5050 = dynamic(() => import('@/components/_sections/Section5050'))
const SectionMarqueeBanner = dynamic(() => import('@/components/_sections/SectionMarqueeBanner'))
const SectionNewsletterForm = dynamic(() => import('@/components/_sections/SectionNewsletterForm'))
const SectionPortraitImages = dynamic(() => import('@/components/_sections/SectionPortraitImages'))
const SectionSvgBanner = dynamic(() => import('@/components/_sections/SectionSvgBanner'))

import { useAnalytics } from '@/hooks/Analytics'
import styled from '@emotion/styled'
import { lin } from '@theme'
import PageLoader from '@/components/PageLoader'
import { useGlobalE } from '@/hooks/Global-E'
import { useRouter } from 'next/router'

const Root = styled.div`
  position: relative;
  display: grid;
  ${(p) => (p.rowGap >= 0 && p.rowGapMobile >= 0 ? lin('grid-gap', p.rowGapMobile, p.rowGap) : '')}
`

const Index = ({ page, shopstory }) => {
  const Analytics = useAnalytics()
  const { data } = useGlobalE() || {}
  const router = useRouter()
  const [isCountryUpdated, setIsCountryUpdated] = useState(false)
  const isFirstLoad = useRef(true)

  useEffect(() => {
    Analytics.pageView()
  }, [])

  // useEffect(() => {
  //   const updateQueryParameters = (key, value) => {
  //     if (!key || !value) return

  //     const updatedQuery = {
  //       ...router.query,
  //       [key]: value
  //     }

  //     Object.keys(updatedQuery).forEach((key) => {
  //       if (updatedQuery[key] === undefined) delete updatedQuery[key]
  //     })

  //     router.push({
  //       pathname: router.pathname,
  //       query: updatedQuery
  //     })
  //   }

  //   if (data && data.country) {
  //     updateQueryParameters('country', data.country.value)
  //   }
  // }, [data])
  // useEffect(() => {
  //   const updateQueryParameters = (key, value) => {
  //     if (!key || !value) return

  //     router.push({
  //       pathname: router.pathname,
  //       query: {
  //         ...router.query,
  //         [key]: value
  //       }
  //     })
  //   }

  //   if (data && data.country) {
  //     updateQueryParameters('country', data.country.value)
  //   }
  // }, [data, router.query.handle])

  const storedData = localStorage.getItem('selectedCountry')
  let selectedCountry = ''
  if (storedData) {
    const parsedData = JSON.parse(storedData)
    selectedCountry = parsedData.value
  } else {
    console.log('No data found in localStorage for selectedCountry')
  }

  useEffect(() => {
    const updateCountryInURL = async () => {
      if (router.pathname === '/' && !isCountryUpdated) {
        const [path, initialQuery] = router.asPath.split('?')

        let queryParams = new URLSearchParams(initialQuery)

        if (queryParams.has('country')) {
          queryParams.set('country', selectedCountry || (await getCountryFromIP()))
        } else {
          queryParams.append('country', selectedCountry || (await getCountryFromIP()))
        }

        const updatedUrl = `${path}?${queryParams.toString()}`

        router.replace(updatedUrl, undefined, { shallow: true })
        setIsCountryUpdated(true)
      }
    }

    updateCountryInURL()
  }, [router.pathname, isCountryUpdated, selectedCountry])


  const gaps = {
    rowGap: page.rowGap ?? 0,
    rowGapMobile: page.rowGapMobile ?? 0,
    columnGap: page.columnGap ?? 0,
    columnGapMobile: page.columnGapMobile ?? 0
  }

  if (shopstory) {
    return (
      <ShopstoryProvider>
        <Shopstory content={shopstory.content} meta={shopstory.meta} />
      </ShopstoryProvider>
    )
  }

  const RenderComponent = (section, i) => {
    switch (section.__typename) {
      case 'SectionMarqueeBanner':
        return <SectionMarqueeBanner {...section} key={i} />
      case 'SectionPortraitImages':
        return <SectionPortraitImages {...section} key={i} />
      case 'SectionNewsletterForm':
        return <SectionNewsletterForm key={i} />
      case 'SectionSvgBanner':
        return <SectionSvgBanner {...section} key={i} priority={i === 0} />
      case 'Section5050':
        return <Section5050 {...section} key={i} priority={i === 0} {...gaps} />
      default:
        console.log(`Can't render type of section: ${section.__typename}`)
        return null
    }
  }

  return (
    <>
      <Suspense fallback={<PageLoader />}>
        <Root {...gaps}>
          {page.sectionsCollection.items.map((section, i) => {
            if (!section) {
              return null
            }
            {
              RenderComponent(section, i)
            }
          })}
        </Root>
      </Suspense>
    </>
  )
}

export async function getStaticProps({ preview = false, previewData = null }) {
  const responses = await Promise.all([fetchGlobalSettings(preview), fetchHome(previewData?.entryId ?? null)])

  const globalSettings = responses[0]
  const page = responses[1]

  let shopstory = null

  if (page.enableShopstory) {
    const shopstoryClient = new ShopstoryClient(shopstoryConfig, {
      locale: 'en-US',
      contentful: {
        preview: !!preview
      }
    })

    const content = shopstoryClient.add(page.config)
    const meta = await shopstoryClient.build()

    shopstory = {
      content,
      meta
    }
  }

  const analyticsParams = {
    event: 'HomeView',
    pageType: 'home',
    ecomm_pagetype: 'home',
    pageTitle: globalSettings?.seoTitle + ' | ' + globalSettings?.pageTitleSuffix
  }

  return {
    props: { globalSettings, page, preview, previewData, analyticsParams, shopstory },
    revalidate: 10
  }
}

export const config = { amp: 'hybrid' }

export default Index
